'use client';
import { BannerImageEntityDesktop } from '@entities/desktop/banner-image';
import React from 'react';

import styles from '@/app/[locale]/page.module.scss';
import {
  BLOGGERS_COLLABORATION,
  CONSTANT_PROMOTION,
} from '@/shared/constants/links';
import { Plate } from '@/shared/ui/desktop/plate';

export const MainPromoMiddleBanners = () => {
  return (
    <>
      <Plate
        variant={'16'}
        onClick={() => window.location.assign(BLOGGERS_COLLABORATION)}
        className={styles['promo-plate']}
        key={'middle-banner-1'}
      >
        <BannerImageEntityDesktop variant={'bloggers-collaboration'} />
      </Plate>
      <Plate
        variant={'16'}
        onClick={() => window.location.assign(CONSTANT_PROMOTION)}
        className={styles['promo-plate']}
        key={'middle-banner-2'}
      >
        <BannerImageEntityDesktop variant={'constant-promotion'} />
      </Plate>
      <Plate
        variant={'16'}
        onClick={() => alert('banner click')}
        className={styles['promo-plate']}
        key={'middle-banner-3'}
      >
        <BannerImageEntityDesktop variant={'social-boost'} />
      </Plate>
    </>
  );
};
