'use client';
import { BannerImageEntityDesktop } from '@entities/desktop/banner-image';
import { Autoplay, EffectFade, Swiper, SwiperSlide } from '@widgets/swiper';
import { useRouter } from 'next/navigation';
import React, { useEffect, useRef } from 'react';

import styles from './carousel.module.scss';

export const MainPromoTopBannersCarousel = ({}: {}) => {
  const router = useRouter();
  const topBannersEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = document.body.querySelector(
      '#scroll-anchor',
    ) as HTMLDivElement;

    if (element) {
      topBannersEndRef.current = element;
    }
  }, []);

  return (
    <Swiper
      loop
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      modules={[Autoplay, EffectFade]}
      effect={'fade'}
      className={'swiper-desktop'}
    >
      <SwiperSlide
        onClick={() =>
          topBannersEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
        className={styles['swiper-top-banner']}
      >
        <BannerImageEntityDesktop variant={'concept1'} />
      </SwiperSlide>
      <SwiperSlide
        onClick={() => router.push('/shop')}
        className={styles['swiper-top-banner']}
      >
        <BannerImageEntityDesktop variant={'concept2'} />
      </SwiperSlide>
      <SwiperSlide
        onClick={() =>
          topBannersEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
        className={styles['swiper-top-banner']}
      >
        <BannerImageEntityDesktop variant={'concept3'} />
      </SwiperSlide>
    </Swiper>
  );
};
