'use client';
import { BannerImageEntityDesktop } from '@entities/desktop/banner-image';
import { useQueryString } from '@hooks/client';
import { Autoplay, Swiper, SwiperSlide } from '@widgets/swiper';
import { useRouter } from 'next/navigation';
import React from 'react';

import styles from './carousel.module.scss';

export const MainPromoBottomBannersCarousel = () => {
  const router = useRouter();
  const { createQueryString } = useQueryString();

  const handleBottomBannerClick = () =>
    router.push(
      createQueryString({
        pathname: '/shop',
        name: 'sale',
        value: 'hot-sale',
        asMultipleParams: true,
        enableToggle: true,
      }),
    );

  return (
    <Swiper
      loop
      spaceBetween={5}
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      modules={[Autoplay]}
      className={'swiper-desktop'}
    >
      <SwiperSlide
        className={styles['swiper-bottom-banner']}
        onClick={handleBottomBannerClick}
      >
        <BannerImageEntityDesktop variant={'be-cool-1'} />
      </SwiperSlide>
      <SwiperSlide
        className={styles['swiper-bottom-banner']}
        onClick={handleBottomBannerClick}
      >
        <BannerImageEntityDesktop variant={'be-cool-2'} />
      </SwiperSlide>
      <SwiperSlide
        className={styles['swiper-bottom-banner']}
        onClick={handleBottomBannerClick}
      >
        <BannerImageEntityDesktop variant={'save-money'} />
      </SwiperSlide>
    </Swiper>
  );
};
